<template>

  <div id="app">
    <router-view></router-view>
  </div>

</template>
<script>
import {getUserInfo,initUser,setUserInfo} from '@/utils/util';
import commonApi from '@/api/common';
import wx from 'weixin-js-sdk'
export default {
    async mounted(){
      await initUser();
      
      if(this.isWeixin()){
        if(!getUserInfo()){
          const data = await commonApi.openLogin();
          if(data){
            setUserInfo(data.userinfo);
            window.location.reload()
          }
        }
        var currentUrl = window.location.href.split('#')[0];
        commonApi.wx_config(currentUrl).then((config)=>{
          const {share_title,share_synopsis,share_img,appId,nonceStr,timestamp,signature} = config
          wx.config({
            debug: false, // 
            appId, // 必填，公众号的唯一标识
            timestamp , // 必填，生成签名的时间戳
            nonceStr, // 必填，生成签名的随机串
            signature,// 必填，签名
            jsApiList: [
              "onMenuShareTimeline",
              "onMenuShareAppMessage"] // 必填，需要使用的 JS 接口列表
          });
          wx.ready(function(){
            wx.onMenuShareAppMessage({ 
                title: share_title, // 分享标题
                desc: share_synopsis, // 分享描述
                link: currentUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                imgUrl: 'http://gdhdfund.com'+share_img, // 分享图标
                success: function () {
                },
              })
          });
        })

      }else{
        getUserInfo();
      }
      
      


    },
    methods: {
      isWeixin() {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
        } else {
            return false;
        }
      }
    },
};
</script>
<style lang="scss">
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,body,#app{
  height: 100%;
}
</style>
