import userApi from '@/api/user'

export function dateFormat(fmt, date) {
  date = new Date(date*1000)
  let ret;
  const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "m+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "M+": date.getMinutes().toString(),         // 分
      "S+": date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
  };
  return fmt;
}
export function isEmail(email){
    var str = email.trim();
    var regex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if(!regex.test(str)){
        return false
    }else{
        return true
    }
}
export function isPwd(str){
    var str = str.trim();
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/
    if(!regex.test(str)){
        return false
    }else{
        return true
    }
}
export function isPhone(phone){
    var str = phone.trim();
    var regex = /^1[3456789]\d{9}$/;
    if(!regex.test(str)){
       return false
    }else{
        return true
    }

// 验证身份证
}
export function isIdCard(str = ''){
    str = str.trim();
    // var regex = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[12])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/;
    // if(!regex.test(str)){
    //     return false
    // }else{
    //     return true
    // }
    return !!str
}
export function setUserInfo(data){
    window.localStorage.setItem('userInfo',JSON.stringify(data));
    setCookie('token',data.token);
}
export async function initUser(){
    try {
        let user = getUserInfo()
        if(user){
            const data = await userApi.getInfo();
            setUserInfo(data.userinfo);
            setCookie('token',data.userinfo.token);
        }
    } catch (error) {
        clearUserInfo();
    }
    
}
export function getUserInfo(){
    let data = window.localStorage.getItem('userInfo');
    let userInfo = JSON.parse(data);
    console.log(userInfo)
    if(userInfo){
        return userInfo
    }else{
        window.localStorage.clear('userInfo');
        clearCookie('token');
        return false;
    }
}
export function clearUserInfo(){
    window.localStorage.clear('userInfo');
    clearCookie('token');
}
export function setCookie(cname, cvalue, exdays) {
    document.cookie = cname + "=" + cvalue + "; ";

}
  //获取cookie
export  function getCookie (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
    //   console.log(c)
      while (c.charAt(0) == ' ') c = c.substring(1);
      if (c.indexOf(name) != -1){
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  //清除cookie
export  function clearCookie (name) {
    setCookie(name, "", -1);
}

export function setMainRoute(path){
    window.localStorage.setItem('mainRoute',path);
}
export function getMainRoute(){
    return window.localStorage.getItem('mainRoute');
}

