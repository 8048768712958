import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/views/HomeView.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/Home.vue'),
        meta:{
          title: '首页'
        }
      },
      {
        path: '/aboutUs',
        name: 'AboutUs',
        component: () => import('@/views/aboutUs/AboutUs.vue'),
        meta:{
          title: '关于我们'
        }
      },
      {
        path: '/coreTeam',
        name: 'CoreTeam',
        redirect: '/coreTeam/investmentTeam',
        component: () => import('@/views/coreTeam/CoreTeam.vue'),
        children: [
          {
            path: '/coreTeam/investmentTeam',
            name: 'InvestmentTeam',
            meta:{
              title: '核心团队'
            },
            component: () => import('@/views/coreTeam/InvestmentTeam.vue'),
          },
          {
            path: '/coreTeam/riskControlTeam',
            name: 'RiskControlTeam',
            meta:{
              title: '风控团队'
            },
            component: () => import('@/views/coreTeam/RiskControlTeam.vue'),
          },
        ]
      },
      {
        path: '/investment',
        name: 'Investment',
        redirect: '/investment/concept',
        component: () => import('@/views/investment/Investment.vue'),
        children: [
          {
            path: '/investment/concept',
            name: 'InvestmentConcept',
            meta:{
              title: '投资理念'
            },
            component: () => import('@/views/investment/InvestmentConcept.vue'),
          },
          {
            path: '/investment/system',
            name: 'InvestmentSystem',
            meta:{
              title: '投研体系'
            },
            component: () => import('@/views/investment/System.vue'),
          },
          {
            path: '/investment/decision',
            name: 'InvestmentDecision',
            meta:{
              title: '决策体系'
            },
            component: () => import('@/views/investment/InvestmentDecision.vue'),
          },
          {
            path: '/investment/riskControl',
            name: 'InvestmentRiskControl',
            meta:{
              title: '风控体系'
            },
            component: () => import('@/views/investment/InvestmentRiskControl.vue'),
          },
          {
            path: '/investment/riskControlMeasure',
            name: 'InvestmentRiskControlMeasure',
            meta:{
              title: '风控措施'
            },
            component: () => import('@/views/investment/InvestmentRiskControlMeasure.vue'),
          },
        ]
      },
      {
        path: '/fund',
        name: 'Fund',
        redirect: '/fund/procedure',
        component: () => import('@/views/fund/Fund.vue'),
        children: [
          {
            path: '/fund/procedure',
            name: 'FundProcedure',
            meta:{
              title: '旗下基金'
            },
            component: () => import('@/views/fund/FundProcedure.vue'),
          },
          {
            path: '/fund/appointment',
            name: 'FundAppointment',
            meta:{
              title: '旗下基金'
            },
            component: () => import('@/views/fund/FundAppointment.vue'),
          },
          {
            path: '/fund/information',
            name: 'FundInformation',
            meta:{
              title: '旗下基金'
            },
            component: () => import('@/views/fund/FundInformation.vue'),
          },
          {
            path: '/fund/productList',
            name: 'FundProductList',
            meta:{
              title: '旗下基金'
            },
            component: () => import('@/views/fund/FundProductList.vue'),
          },
          {
            path: '/fund/productDetail',
            name: 'FundProductDetail',
            meta:{
              title: '旗下基金'
            },
            component: () => import('@/views/fund/FundProductDetail.vue'),
          },
          {
            path: '/fund/information/detail',
            name: 'InformationDetail',
            component: () => import('@/views/fund/FunInformationDetail.vue'),
            meta:{
              title: '旗下基金'
            },
          },
        ]
      },
      {
        path: '/news',
        name: 'News',
        redirect: '/news/notice',
        component: () => import('@/views/news/News.vue'),
        children: [
          {
            path: '/news/notice',
            name: 'NewsNotice',
            component: () => import('@/views/news/NewsNotice.vue'),
            meta:{
              title: '新闻资讯'
            },
          },
          {
            path: '/news/avionicsViewpoint',
            name: 'NewsAvionicsViewpoint',
            component: () => import('@/views/news/NewsAvionicsViewpoint.vue'),
            meta:{
              title: '新闻资讯'
            },
          },
          {
            path: '/news/notice/detail',
            name: 'NewsNoticeDetail',
            component: () => import('@/views/news/NewsNoticeDetail.vue'),
            meta:{
              title: '新闻资讯'
            },
          },
        ]
      },
      {
        path: '/joinUs',
        name: 'JoinUs',
        component: () => import('@/views/joinUs/JoinUs.vue'),
        meta:{
          title: '招贤纳士'
        }
      },
      {
        path: '/contactUs',
        name: 'ContactUs',
        meta:{
          title: '联系我们'
        },
        component: () => import('@/views/contactUs/ContactUs.vue'),
      },
      {
        path: '/usercenter',
        name: 'UserCenter',
        component: () => import('@/views/login/UserCenter.vue'),
        meta:{
          title: '个人中心'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
    meta:{
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/login/Register.vue'),
    meta:{
      title: '注册'
    }
  },
  {
    path: '/resetPwd',
    name: 'ResetPwd',
    component: () => import('@/views/login/ResetPwd.vue'),
    meta:{
      title: '重置密码'
    }
  },
  {
    path: '/resetMobile',
    name: 'ResetMobile',
    component: () => import('@/views/login/ResetMobile.vue'),
    meta:{
      title: '重置手机号'
    }
  },
  {
    path: '/holderBinding',
    name: 'HolderBinding',
    component: () => import('@/views/login/HolderBinding.vue'),
    meta:{
      title: '持有人绑定'
    }
  },
  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to,from,next) => {

  document.title = '航电基金—' + to.meta.title;

  document.documentElement.scrollTop = 0;
  
  next();

})

export default router
