import axios from 'axios'
import { Message, Loading } from 'element-ui'
import { getUserInfo } from './util'
const ConfigBaseURL = '/' //默认路径，这里也可以使用env来判断环境
let loadingInstance = null //这里是loading
//使用create方法创建axios实例
export const Service = axios.create({
  timeout: 60000, // 请求超时时间
  baseURL: ConfigBaseURL,
})
// 添加请求拦截器
Service.interceptors.request.use(config => {
  loadingInstance = Loading.service({
    lock: true,
    text: 'loading...'
  })
  let user = getUserInfo();
  if(user && user.token){
    config.headers.HTTP_TOKEN = user.token
  }
  return config
})
// 添加响应拦截器
Service.interceptors.response.use(response => {
  loadingInstance.close()
  // console.log(response)
  return response.data
}, error => {
  console.log('TCL: error', error)
  const msg = error.Message !== undefined ? error.Message : ''
  Message({
    message: '网络错误' + msg,
    type: 'error',
    duration: 3 * 1000
  })
  // return false;
  loadingInstance.close()
  return Promise.reject(error)
})

export function get (url, data = {}) {
  let user = getUserInfo();
  if(user && user.token){
    data.token = user.token
  }
  return new Promise((resolve, reject) => {
    Service
      .get(url, {
        params: data
      })
      .then((response) => {
        if(response.code === 1){
          resolve(response.data)
        }else{
          Message({
            message: response.msg,
            type: 'error',
            duration: 3 * 1000
          })
          resolve(response.data)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

// post请求
export function post (url, data = {}) {
  let user = getUserInfo();
  if(user && user.token){
    data.token = user.token
  }
  return new Promise((resolve, reject) => {
    Service.post(url, data).then(
      (response) => {
        
        if(response.code === 1){
          resolve(response.data)
        }else{
          Message({
            message: response.msg,
            type: 'error',
            duration: 3 * 1000
          })
          resolve(response.data)
        }
      },
      (err) => {
        reject(err)
      }
    )
  })
}