import {get,post} from '@/utils/request'
export function wx_config (url) {
  return get(`/api/common/wx_config?url=${url}`)
}
export function openLogin () {
  return post('/api/common/open_login')
}
export default {
  wx_config,
  openLogin
}

