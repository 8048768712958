import {get,post} from '@/utils/request'
export function smsSend (data) {
  return post('/api/sms/send',data)
}
export function smsCheck (data) {
  return post('/api/sms/check',data)
}
export function commonUpload (data) {
  return post('/api/common/upload',data)
}
export function login (data) {
  return post('/api/user/login',data)
}
export function mobilelogin (data) {
  return post('/api/user/mobilelogin',data)
}
export function idcardlogin (data) {
  return post('/api/user/idcardlogin',data)
}
export function register (data) {
  return post('/api/user/register',data)
}
export function resetpwd (data) {
  return post('/api/user/resetpwd',data)
}
export function resetmobile (data) {
  return post('/api/user/resetmobile',data)
}
export function logout () {
  return post('/api/user/logout')
}
export function getInfo () {
  return post('/api/user/info')
}
export default {
  smsSend,
  smsCheck,
  commonUpload,
  login,
  mobilelogin,
  register,
  logout,
  idcardlogin,
  resetpwd,
  resetmobile,
  getInfo
}

